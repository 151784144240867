import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Typography, Form, Input, Button, notification, Spin } from 'antd';
import { FacebookOutlined } from '@ant-design/icons';
import { MdOutlineEmail, MdLockOutline } from "react-icons/md";
import {
    Link
} from "react-router-dom";

import { useHistory } from "react-router-dom";

import { ForgetVarification, ForgetResendOTP } from '../../../services/apiInteraction';
import OtpTimer from 'otp-timer'

// import CSS 
import '../auth.css'
import Timer from 'otp-timer';
import { min } from 'moment';

const { Title, Paragraph } = Typography;

const validateMessages = (data) => {
    const args = {
        message: 'Error',
        description:
            `${data.message}`,
        duration: 5,
    };
    notification.error(args);
};


function ForgetConfirmation() {

    let history = useHistory();

    const [loader, setLoader] = useState(false)
    const [resendCount, setResetCount] = useState(0);
    const [timer, setTimer] = useState({ index: 0, show: true });
    const onFinish = async (values) => {

        setLoader(true)

        let data = {
            OTP: values.ConfirmationCode
        }

        try {

            let resultHandle = await ForgetVarification(data)


            if (resultHandle?.success == true) {

                if (resultHandle?.success == true) {
                    setLoader(false)
                    localStorage.setItem('token', resultHandle.message.accessToken)
                    history.push('./create-new-password')
                }

                else {
                    validateMessages(resultHandle);
                    setLoader(false)
                }

            }
            else {

                console.log("error")
                validateMessages(resultHandle);
                setLoader(false)

            }
        }
        catch (err) {
            console.log(err)
        }

    };


    const timerSeconds = [30, 60, 300, 1800,3200];


    const upgradeTime = () => {
        setTimeout(() => {
            setTimer(prev => prev = { ...prev, show: true })
        }, 10);
    }

    async function Resend() {


        setTimer(prev => prev = { index: prev.index + 1, show: false })
        try {
            setLoader(true)
            let resultHandle = await ForgetResendOTP()

            if (resultHandle.success == true) {
                upgradeTime()
                setLoader(false)
            }

            else {
                upgradeTime()
                setLoader(false)
            }
        }
        catch (err) {
            console.log(err)
            upgradeTime()
        }

    }

    return (
        <div style={{ height: '100vh', position: 'relative' }} className="gray-background">
            <Spin className="loader" spinning={loader} size="large" />
            <Row style={{ height: '100vh', position: 'relative' }}>
                <Col md={8} xs={24} >

                </Col>
                <Col style={{ alignSelf: 'center', justifyContent: 'center', display: 'flex' }} className="position-relative" md={8} xs={24} >
                    <Card bordered={false} className="custom-card responsive-card">
                        <Title className="d-flex justify-content-center" level={5}>Enter confirmation code</Title>
                        <Paragraph style={{ textAlign: 'center' }}>Enter the confirmation code we sent to who'son@gmail.com.</Paragraph>

                        <Form
                            name="normal_login"
                            className="login-form"
                            initialValues={{ remember: true }}
                            onFinish={onFinish}
                        >
                            <Form.Item
                                name="ConfirmationCode"
                                rules={[{ required: true, message: 'Please input your Username!' }]}
                            >
                                <Input className="login-field" placeholder="Verification Code" />
                            </Form.Item>

                            <Form.Item className="position-relative">
                                <Button
                                    type="primary" htmlType="submit" className="button mt-5 w-100" >
                                    Next
                                </Button>
                            </Form.Item>
                        </Form>
                        <p style={{ position: 'absolute', bottom: '0px', left: '0px', width: '100%', cursor: 'pointer' }} className="d-flex justify-content-center g-color font-16">
                        
                            {timer.show && <Timer seconds={(timerSeconds[timer.index])} resend={Resend}  background='#27B824'/>}
                        </p>
                    </Card>
                </Col>
                <Col md={8} xs={24} >

                </Col>
            </Row>
        </div>
    )
}

export default ForgetConfirmation;