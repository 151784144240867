// import { initializeApp } from 'firebase/app';
import firebase from 'firebase';

const config = {
  apiKey: "AIzaSyA22v2LQyu8UcUln8-7TcLvPYhLnel_Gcg",
  authDomain: "whos-on-340806.firebaseapp.com",
  projectId: "whos-on-340806",
  storageBucket: "whos-on-340806.appspot.com",
  messagingSenderId: "679274960122",
  appId: "1:679274960122:web:5301af8c8aae596ede6543",
  measurementId: "G-K3QPSGNBDT"
};

firebase.initializeApp(config);


export default firebase



