export const statusConstant = {
    REQUEST : 1,
    ACCEPT : 2,
    REJECT : 3,
    UNFOLLOW : 4,
    BLOCK :5
}

export const notificationMessage = {
    2: 'is on',
}
